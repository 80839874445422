import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Navigator from './components/Navigator';
import Home from './pages/Home';
import NotFound from './pages/NotFound'
import Projects from './pages/Projects'
import Team from './pages/Team';
import SocialLinks from "./components/SocialLinks";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <html>
        <body>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigator/>}>
                        <Route index element={<Home/>}/>
                        <Route path='projects' element={<Projects/>}/>
                        <Route path='team' element={<Team/>}/>
                        <Route path="*" element={<NotFound/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
            <footer>
                <SocialLinks/>
            </footer>
        </body>
    </html>
);
