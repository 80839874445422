import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import MemberGrid from "../components/MemberGrid";
import PartnerModal from "../components/PartnerModal";
import PlaceHolderGrid from "../components/PlaceHolderGrid";

import './styles/team.css'

const Team = () => {
    const [members, setMembers] = useState([]);
    const [loadingMembers, setLoadingMembers] = useState(false);

    useEffect(() => {
        // Members
        axios.get("https://v1-zquh3chrmq-uc.a.run.app/members")
            .then(response => {
                setMembers(response.data);
                setLoadingMembers(true);
            })
            .catch(error => console.error(error));
    }, []);

    return (
        <div className="background">
            <Box className='section'>
                <Typography level="title-md" textColor="#000000" sx={{ opacity: 0.5 }}>
                    Who works at Olympus Code?
                </Typography>
                <Box>
                    <Stack spacing={2}>
                        <Typography level="body-md">
                            At Olympus Code, our team is driven by a shared passion for innovation and creativity. Every member is encouraged to bring bold ideas forward, knowing that their contributions matter not only to the projects at hand but also to the growth and development of those around them. We pride ourselves on cultivating an environment where individuals can pursue their dreams and make meaningful contributions, working together to shape exciting, impactful technology solutions for our clients.
                        </Typography>
                        <Typography level="body-md">
                            Accountability is a core value at Olympus Code, fostering a culture of responsibility and transparency. We believe in owning our actions and outcomes, understanding that trust forms the foundation of all our relationships. By holding ourselves to high standards and delivering on our promises, we build confidence both within our team and with our clients, ensuring that they can depend on us as partners in achieving their goals.
                        </Typography>
                        <Typography level="body-md">
                            Integrity and teamwork are the heart of how we operate. Our commitment to ethical principles guides our work and our interactions, setting a standard for honesty and respect across every project. We believe in the power of collaboration, both internally and with our clients, to create solutions that exceed the sum of individual efforts. Together, we aspire to build a community of problem-solvers who not only strive for technical excellence but also prioritize meaningful partnerships and shared success.
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Box className='sectionWork'>
                <Typography level='title-lg' sx={{marginBottom: '8px'}}>
                    Partner with Olympus Code and bring your bold ideas to life.
                </Typography>
                <PartnerModal/>
            </Box>
            <Box className='section'>
                <Typography level="title-md" textColor="#000000" sx={{ opacity: 0.5 }}>
                    Team
                </Typography>
                <Box>
                    { !loadingMembers ?
                        (<PlaceHolderGrid type='member'/>)
                        :
                        (<MemberGrid projects={members}/>)
                    }
                </Box>
            </Box>
        </div>
    );
};

export default Team;
