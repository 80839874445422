import * as React from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Stack from '@mui/joy/Stack';
import Textarea from '@mui/joy/Textarea';
import emailjs from '@emailjs/browser';

 const ContactModal = () => {
    const [open, setOpen] = React.useState(false);
    const form = React.useRef();

     const sendEmail = (e) => {
         e.preventDefault();

         emailjs
             .sendForm('hermes_mail', 'contact_email', form.current, {
                 publicKey: 'Wl4TKdFBISoJp1qYE',
             })
             .then(
                 () => {
                     console.log('[EmailJS] Successfully sent email.');
                 },
                 (error) => {
                     console.error('[EmailJS] Error: Failed to send.');
                 }
             );
     };

    return (
        <React.Fragment>
            <Link
                className='link-alt'
                onClick={() => setOpen(true)}
                to="#"
            >
                Contact
            </Link>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog sx={{ backgroundColor: '#FAEDCD', borderColor: '#d2a272'}}>
                    <DialogTitle>Contact Us</DialogTitle>
                    <DialogContent>Tell us how we can help or just leave us a message.</DialogContent>
                    <form
                        ref={form}
                        onSubmit={(event) => {
                            sendEmail(event);
                            setOpen(false);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input autoFocus required sx={{
                                    '&::before': {
                                        display: 'none',
                                    },
                                    '&:focus-within': {
                                        outline: '2px solid var(--Input-focusedHighlight)',
                                        outlineColor: '#d2a272',
                                    }
                                }} name='from_name'/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input required sx={{
                                    '&::before': {
                                        display: 'none',
                                    },
                                    '&:focus-within': {
                                        outline: '2px solid var(--Input-focusedHighlight)',
                                        outlineColor: '#d2a272',
                                    },
                                }} name='reply_to'/>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Message</FormLabel>
                                <Textarea minRows={5} required sx={{
                                    '&::before': {
                                        display: 'none',
                                    },
                                    '&:focus-within': {
                                        outline: '2px solid var(--Input-focusedHighlight)',
                                        outlineColor: '#d2a272',
                                    },
                                }} name='message'/>
                            </FormControl>
                            <Button type="submit" sx={{
                                backgroundColor: '#d2a272',
                                '&:hover': {
                                    backgroundColor: '#bd9166'
                                }
                            }}>Submit</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}

export default ContactModal;