import React, { useState, useEffect } from "react";
import axios from "axios";

import './styles/project.css'
import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import ProjectGrid from "../components/ProjectGrid";
import PartnerModal from "../components/PartnerModal";
import PlaceHolderGrid from "../components/PlaceHolderGrid";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [loadingProjects, setLoadingProjects] = useState(false);

    useEffect(() => {
        // Projects
        axios.get("https://v1-zquh3chrmq-uc.a.run.app/projects")
            .then(response => {
                setProjects(response.data);
                setLoadingProjects(true);
            })
            .catch(error => console.error(error));
    }, []);

    return (
        <div className="background">
            <Box className='section'>
                <Typography level="title-md" textColor="#000000" sx={{ opacity: 0.5 }}>
                    Why work with Olympus Code?
                </Typography>
                <Box>
                    <Stack spacing={2}>
                        <Typography>
                            At Olympus Code, our approach to completing projects is deeply rooted in our core values, starting with passion. Every project we undertake is driven by a commitment to excellence and innovation. We immerse ourselves in our clients’ goals, investing the energy and creativity necessary to deliver outstanding results. By fostering a culture where bold ideas are encouraged, our team consistently pushes the boundaries of what’s possible, ensuring that each solution reflects our dedication to making a meaningful impact.
                        </Typography>
                        <Typography>
                            Accountability is central to how we manage and execute projects. From the initial planning stages to final delivery, we take full responsibility for every aspect of our work. Clear communication, meticulous attention to detail, and transparency are at the forefront of our process, enabling us to build trust with our clients at every step. We recognize that delivering on promises is essential to maintaining strong relationships and achieving shared success.
                        </Typography>
                        <Typography>
                            We complete projects with a strong foundation of integrity and teamwork, ensuring that ethical practices and collaboration guide every decision. Acting with honesty and respect, we align with our clients’ visions while leveraging the collective expertise of our team. This partnership-driven approach ensures that we not only meet expectations but exceed them by creating solutions that are innovative, reliable, and scalable. Together, we transform ideas into impactful realities, showcasing the strength of our values through every project we complete.
                        </Typography>
                    </Stack>
                </Box>
            </Box>
            <Box className='sectionWork'>
                    <Typography level='title-lg' sx={{marginBottom: '8px'}}>
                        Partner with Olympus Code and bring your bold ideas to life.
                    </Typography>
                    <PartnerModal/>
            </Box>
            <Box className='section'>
                <Typography level="title-md" textColor="#000000" sx={{ opacity: 0.5 }}>
                    Projects
                </Typography>
                <Box>
                    { !loadingProjects ?
                        (<PlaceHolderGrid type='project'/>)
                        :
                        (<ProjectGrid projects={projects}/>)
                    }
                </Box>
            </Box>
        </div>
    );
};

export default Projects;
