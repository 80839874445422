const NotFound = () => {
    return (
        <div className="App">
            <header className="App-header">
                <h1 className='App-Title'>404 Error</h1>

                <p>
                    We're sorry, but the page you're looking for doesn't exist.
                </p>
            </header>
        </div>
    );
};

export default NotFound;