import ImageButton from "./ImageButton";
import './styles/sociallinks.css'


const SocialLinks = () => {
    return (
        <div className='social-container'>
            <div className='social-content'>
                <ImageButton title='Twitter'
                             icon='https://cdn-icons-png.flaticon.com/512/12107/12107622.png'
                             link='https://twitter.com/olympuscode'/>
                <ImageButton title='Instagram'
                             icon='https://cdn-icons-png.flaticon.com/512/6422/6422200.png'
                             link='https://www.instagram.com/olympuscode/'/>
            </div>
            <div className='social-content'>
                Olympus Code LLC
            </div>
        </div>
    )
};

export default SocialLinks;