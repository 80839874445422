import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import MemberGrid from "../components/MemberGrid";
import ProjectGrid from "../components/ProjectGrid";

import './styles/home.css';
import PlaceHolderGrid from "../components/PlaceHolderGrid";

function Home() {
    const [members, setMembers] = useState([]);
    const [projects, setProjects] = useState([]);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [loadingMembers, setLoadingMembers] = useState(false);

    useEffect(() => {
        // Members
        axios.get("https://v1-zquh3chrmq-uc.a.run.app/members")
            .then(response => {
                setMembers(response.data);
                setLoadingMembers(true);
            })
            .catch(error => console.error(error));

        // Projects
        axios.get("https://v1-zquh3chrmq-uc.a.run.app/projects")
            .then(response => {
                setProjects(response.data);
                setLoadingProjects(true);
            })
            .catch(error => console.error(error));
    }, []);

  return (
    <div className="background">
        <Box className='section'>
            <Typography level="title-md" textColor="#000000" sx={{ opacity: 0.5 }}>
                About Olympus Code
            </Typography>
            <Box>
                <Typography level="body-md" textColor="#000000">
                    Welcome to Olympus Code, your trusted partner in innovative software solutions and strategic
                    consulting services. At Olympus Code, we believe in the power of technology to transform
                    businesses and drive meaningful change. Our mission is simple yet profound: to empower businesses of
                    all sizes to thrive in the digital age.
                    We do this by delivering cutting-edge software solutions and personalized consulting services
                    tailored to meet the unique needs of each client.
                </Typography>
            </Box>
        </Box>
        <Box className='section'>
            <Typography level="title-md" textColor="#000000" sx={{ opacity: 0.5 }}>
                Projects
            </Typography>
            <div>
                { !loadingProjects ?
                    (<PlaceHolderGrid type='project'/>)
                    :
                    (<ProjectGrid projects={projects}/>)
                }
            </div>
        </Box>
        <Box className='section'>
            <Typography level="title-md" textColor="#000000" sx={{ opacity: 0.5 }}>
                Team
            </Typography>
            <div>
                { !loadingMembers ?
                    (<PlaceHolderGrid type='member'/>)
                    :
                    (<MemberGrid projects={members}/>)
                }
            </div>
        </Box>
    </div>
  );
}

export default Home;
