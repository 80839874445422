import * as React from "react";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";

const MemberCard = (props) => {
    return (
        <Card sx={{ minHeight: '280px', width: 320 }}>
            <CardCover>
                <img
                    src={props.data.image}
                    loading="lazy"
                    alt=""
                />
            </CardCover>
            <CardCover
                sx={{
                    background:
                        'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                }}
            />
            <CardContent sx={{ justifyContent: 'flex-end' }}>
                <Typography level="title-lg" textColor="#fff">
                    {props.data.name}
                </Typography>
                <Typography level="title-lg" textColor="#fff">
                    {props.data.title}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default MemberCard
