import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import ProjectCard from "./ProjectCard";

import "./styles/grid.css";

const ProjectGrid = (data) => {
    const projects = data.projects;
    return (
        <Box className='grid'>
            <Stack direction="row" spacing={1}>
                {projects.map(project => (
                    <ProjectCard key={project._id} data={project}/>
                ))}
            </Stack>
        </Box>
    );
}

export default ProjectGrid;
