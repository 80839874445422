import { Outlet, Link } from "react-router-dom";

import './styles/navigator.css'
import React from "react";

import {Box} from "@mui/joy";
import ContactModal from "./ContactModal";

const Navigator = () => {
    return (
        <div className='navigator-bar'>
            <Box>
                <Link className='link' to="/">Olympus Code</Link>
                <Link className='link-alt' to="/projects">Projects</Link>
                <Link className='link-alt' to="/team">Team</Link>
                <ContactModal/>
            </Box>
            <Outlet/>
        </div>
    )
};

export default Navigator;