import * as React from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";
import Typography from '@mui/joy/Typography';


const ProjectCard = (props) => {
    return (
        <Card sx={{ minHeight: '280px', width: 320 }}>
            <CardCover>
                <img
                    src={props.data.image}
                    loading="lazy"
                    alt=""
                />
            </CardCover>
            <CardCover
                sx={{
                    background:
                        'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                }}
            />
            <CardContent sx={{ justifyContent: 'flex-end' }}>
                <Stack direction='row' spacing={1}>
                    <Typography level="title-lg" textColor="#fff">
                        {props.data.name}
                    </Typography>
                    <Chip
                        color="success"
                        size="sm"
                    >
                        {props.data.status}
                    </Chip>
                </Stack>
            </CardContent>
        </Card>
    );
}

export default ProjectCard
