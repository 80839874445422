import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import MemberCard from "./MemberCard";

import "./styles/grid.css";

const MemberGrid = (data) => {
    const members = data.projects;
    return (
        <Box className='grid'>
            <Stack direction="row" spacing={1}>
                {members.map(member => (
                    <MemberCard key={member._id} data={member}/>
                ))}
            </Stack>
        </Box>
    );
}

export default MemberGrid;
