import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import PlaceHolderCard from "./PlaceHolderCard";

import "./styles/grid.css";

const PlaceHolderGrid = (data) => {
    const type = data.type;
    const placeholders = [
        {_id: '01'},
        {_id: '02'},
        {_id: '03'},
        {_id: '04'},
        {_id: '05'}
    ];
    return (
        <Box className='grid'>
            <Stack direction="row" spacing={1}>
                {placeholders.map(placeholder => (
                    <PlaceHolderCard key={placeholder._id} type={type}/>
                ))}
            </Stack>
        </Box>
    );
}

export default PlaceHolderGrid;
