import * as React from 'react';

import Card from '@mui/joy/Card';
import Skeleton from "@mui/joy/Skeleton";
import Typography from '@mui/joy/Typography';
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";


const PlaceHolderCard = (data) => {
    const type = data.type;

    switch(type) {
        case 'member':
            return (
                <Card sx={{ minHeight: '280px', width: 320 }}>
                    <CardCover>
                        <Skeleton>
                            <img
                                src='#'
                                srcSet="https://images.unsplash.com/photo-1542773998-9325f0a098d7?auto=format&fit=crop&w=320&dpr=2 2x"
                                loading="lazy"
                                alt=""
                            />
                        </Skeleton>
                    </CardCover>
                    <CardCover
                        sx={{
                            background:
                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                        }}
                    />
                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                        <Typography level="title-lg" textColor="#fff">
                            <Skeleton>
                                Calvin
                            </Skeleton>
                        </Typography>
                        <Typography level="title-lg" textColor="#fff">
                            <Skeleton>
                                Owner & Software Developer
                            </Skeleton>
                        </Typography>
                    </CardContent>
                </Card>
            );
        case 'project':
            return (
                <Card sx={{ minHeight: '280px', width: 320 }}>
                    <CardCover>
                        <Skeleton>
                            <img
                                src='#'
                                srcSet="https://images.unsplash.com/photo-1542773998-9325f0a098d7?auto=format&fit=crop&w=320&dpr=2 2x"
                                loading="lazy"
                                alt=""
                            />
                        </Skeleton>
                    </CardCover>
                    <CardCover
                        sx={{
                            background:
                                'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                        }}
                    />
                    <CardContent sx={{ justifyContent: 'flex-end' }}>
                        <Typography level="title-lg" textColor="#fff">
                            <Skeleton>
                                Project_Name
                            </Skeleton>
                        </Typography>
                    </CardContent>
                </Card>
            );
        default:
            console.error('Unknown placeholder type.')
    }
}

export default PlaceHolderCard
