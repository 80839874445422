import './styles/imagebutton.css'

const ImageButton = (props) => {
    function openExternal() {
        try {
            window.open(props.link)
        } catch (e) {
            console.error(`Error: ${e}`)
        }
    }

    return (
        <button className='buttonContainer' onClick={ () => openExternal() }>
            <img src={props.icon} alt={props.title + ' Icon'} />
        </button>
    );
}

export default ImageButton